import React from "react"
import styles from "./index.module.scss"

const WinnersHeader = ({mes}) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {/* Te presentamos al {" "} */}
        Ganadores 
        <span className={styles.strongTitle}>{" "}
       Balón de Potrero
        {/* {mes}
         */}
        </span>
      </div>
      {/* <div className={styles.subtitle}>Elegido por la gente</div> */}
    </div>
  )
}

export default WinnersHeader
