import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import facebook from "../../../images/balondepotrero/facebook.svg";
import instagram from "../../../images/balondepotrero/instagram.svg";

const Countdown = () => {
  return (
    <>
      <div className={styles.mainTextContainer}>
        <div className={styles.mainText}>
          {/* Los ganadores serán anunciados en{" "} */}
          MIRÁ A LOS GANADORES Y MUCHO MÁS EN EL STREAM CON JOACO LÓPEZ Y DANI
          ARCUCCI EN {" "}
          <a
            href="https://www.twitch.tv/elcanaldejoaco"
            target="_blank"
            className={styles.mainTextLink}
          >
            <span className={styles.strongMainText}>
              el canal de Twitch de Joaco 
            </span>
          </a>
        </div>
      </div>
      {/* <div className={styles.bottomContainer}>
        <div className={styles.linksContainer}>
          <div className={styles.link}>Información oficial de la compañía</div>
          <div className={styles.link}>Términos y condiciones</div>
          <div className={styles.link}>Políticas de privacidad</div>
          <div className={styles.link}>Preguntas frecuentes</div>
        </div>
        <div className={styles.socialMediaAndHRContainer}>
          <hr className={styles.rotatedHR} />
          <div className={styles.socialMediaContainer}>
            <div className={styles.socialMediaTitle}>Seguinos en:</div>
            <div className={styles.socialMediaIconsContainer}>
              <img
                className={styles.socialMediaIcon}
                src={facebook}
                alt="Facebook"
              />
              <img
                className={styles.socialMediaIcon}
                src={instagram}
                alt="Instagram"
              />
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Countdown;
