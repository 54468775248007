import React, { useState, useEffect } from "react"
import styles from "./index.module.scss"
import balonDePotreroLogo from "../../../images/balondepotrero/balon-de-potrero.svg"
import quilmeslogo from "../../../images/logos/quilmesLogo.svg"

const Header = () => {
  return (
    <div className={styles.container}>
      <img
        className={styles.awardLogo}
        src={balonDePotreroLogo}
        alt="El Balón de Potrero"
      />
      <img
        className={styles.fansClubLogo}
        src={quilmeslogo}
        alt="Logo Quilmes"
      />
      <div className={styles.mainText}>
        Un trofeo hecho con{" "}
        <span className={styles.strongMainText}>tierra de potreros</span>, para
        entregarle al jugador de potrero de cada mes.
        {/* los mejores jugadores de cada mes. */}
      </div>
    </div>
  )
}

export default Header
