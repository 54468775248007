import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import styles from "../../styles/balondepotrero.module.scss"
// Components
import Header from "../../components/balondepotrero/header"
import Countdown from "../../components/balondepotrero/countdown"
import WinnersHeader from "../../components/balondepotrero/winners-header"
import Players from "../../components/balondepotrero/players"
import Submit from "../../components/balondepotrero/submit"
import Modal from "../../components/balondepotrero/modalForm"
import Footer from "../../components/balondepotrero/footer"

const BalonDeBarro = () => {
  const [activePoll, setActivePoll] = useState(true)
  const [totalSeconds, setTotalSeconds] = useState(0)
  const [currentMonthID, setCurrentMonthID] = useState(0)
  const [selectedPlayer, setSelectedPlayer] = useState()
  const [winners, setWinners] = useState([])
  const [players, setPlayers] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [enabledSubmitButton, setEnabledSubmitButton] = useState(false)
  const [loading, setLoading] = useState(true)

  const [mes, setMes] = useState("")

  useEffect(() => {
    let local = localStorage.getItem("age")
    let session = sessionStorage.getItem("age")
    if (!session && !local)
      navigate("/agegate", { state: { newPath: "/balondepotrero" } })
  }, [])

  useEffect(() => {
    window.dataLayer.push({
      brand: "Quilmes",
      Zone: "SAZ",
      country: "ARG",
      city: "CABA",
      campaign: `${
        window.location.search.includes("?cmp=")
          ? window.location.search.split("?cmp=")[1].split("&")[0]
          : ""
      }`,
      cms: "0",
      event: "pageView",
      language: "es",
      login: false,
      pageName: "Balón de potrero",
      pageType: "Basic page",
      SiteType: "Brand",
      product: "NA",
      sku: "NA",
      userUid: "",
      url: "https://www.quilmes.com.ar/balondepotrero",
      url_campaign: `${
        window.location.search.includes("utm_campaign=")
          ? window.location.search.split("utm_campaign=")[1]
          : ""
      }`,
      Step_number: "",
    })
  }, [])

  const api = "https://wacopaamericamotmback-prd.azurewebsites.net/api"
  // const api = "http://localhost:8080/api";

  useEffect(() => {
    let currentTime = Math.floor(new Date().getTime() / 1000)
    fetch(`${api}/balondepotrero/deadline/${currentTime}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async response => {
      if (response.ok) {
        let data = await response.json()
        setCurrentMonthID(data.idbpmonths)
        let secondsLeft = data.deadline - currentTime
        if (secondsLeft <= 432000) setActivePoll(true)
        else setActivePoll(false)
        setTotalSeconds(secondsLeft)
      } else {
        console.error("Error guardando los datos. \n", response)
      }
    })
  }, [])

  useEffect(() => {
    if (currentMonthID) {
      if (activePoll)
        fetch(`${api}/balondepotrero/monthlyplayers/${currentMonthID}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }).then(async response => {
          if (response.ok) {
            let data = await response.json()
            setPlayers(data)
            setLoading(false)
          } else {
            console.error("Error guardando los datos. \n", response)
          }
        })
      fetch(
        `${api}/balondepotrero/monthlyplayers/allwinners/${
          currentMonthID + 1
        } `,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then(async response => {
        if (response.ok) {
          let data = await response.json()
          setWinners(data)
        } else {
          console.error("Error guardando los datos. \n", response)
        }
      })
    }
  }, [currentMonthID, activePoll])

  useEffect(() => {
    if (selectedPlayer) setEnabledSubmitButton(true)
  }, [selectedPlayer])

  return (
    <div className={styles.mainContainer}>
      {showModal && (
        <Modal setShowModal={setShowModal} selectedPlayer={selectedPlayer} />
      )}
      <Header />
      {winners.length ? <WinnersHeader mes={mes} /> : null}
      {winners.length ? (
        <Players
          position={"delantero"}
          nextSection={null}
          players={winners}
          selectedPlayer={null}
          setSelectedPlayer={null}
          pollView={false}
          // month={winnersOfTheMonth[0].month}
          setMes={setMes}
        />
      ) : null}
      {activePoll ? (
        <Countdown
          totalSeconds={totalSeconds}
          setTotalSeconds={setTotalSeconds}
          pollView={true}
          activePoll={activePoll}
        />
      ) : null}

      {activePoll && players.length ? (
        loading ? (
          <div className={styles.loading}>
            <img
              src={"./load.gif"}
              alt=""
              style={{ backgroundColor: "rgba(130, 196, 236, 0) !important" }}
            />
          </div>
        ) : (
          <>
            <Players
              position={"delantero"}
              nextSection={"submit"}
              players={players}
              selectedPlayer={selectedPlayer}
              setSelectedPlayer={setSelectedPlayer}
              pollView={true}
              month={null}
            />
            <Submit
              enabledSubmitButton={enabledSubmitButton}
              selectedPlayer={selectedPlayer}
              setShowModal={setShowModal}
            />
          </>
        )
      ) : null}

      {!activePoll ? (
        <Countdown
          totalSeconds={totalSeconds}
          setTotalSeconds={setTotalSeconds}
          pollView={false}
          activePoll={activePoll}
        />
      ) : null}
      <Footer />
    </div>
  )
}

export default BalonDeBarro
